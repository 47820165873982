import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/AuthService';

@Component({
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private authService : AuthService , private router : Router) { }

  async ngOnInit() {
    await this.authService.logOutUser();
    this.router.navigate(["/login"]);
  }

}
