<div *ngIf="branchInvalid" class="p-2">
    <span class="text-red-900 font-normal">Error, please contact IT support</span>
    <div class="mt-4">
        <button pButton (click)="back()"><i class="fa-solid fa-arrow-left pr-2"></i>Back</button>
    </div>
</div>
<div *ngIf="helpMessage" class="p-2">
    <span class="font-normal">Please contact Babcock PSC on <b>0800 085 4862</b></span>
    <div class="mt-4">
        <button pButton (click)="back()"><i class="fa-solid fa-arrow-left pr-2"></i>Back</button>
    </div>
</div>
<div *ngIf="errorMessage" class="font-normal p-2">
    <span [innerHTML]="errorMessage"></span>
    <div class="mt-2">
        <button pButton (click)="back()"><i class="fa-solid fa-arrow-left pr-2"></i>Back</button>
    </div>
</div>
<div *ngIf="ShowMainScreen">
    <div class="mt-2 ml-1">
        <img src="/assets/ThriftyLogo.png" style="width: 30vw;">
    </div>
    <div class="ml-2">
        <div class="font-bold text-lg">
            {{branchName}}
        </div>
        <span>To collect your keys, please enter your clarity number below</span>
        <div [formGroup]="form">
            <th-text-input [formGroup]="form" formControlName="clarityNumber" label="Clarity Number">
            </th-text-input>
            <re-captcha siteKey="6LeD-6UnAAAAADLydgK7QqgZoowKvDKrr8iC_3lx" (resolved)="recaptchaResolved($event)">
            </re-captcha>
            <div class="flex flex-row mt-1 w-full">
                <button pButton (click)="retrieveCode()" [disabled]="!resolved"><i
                        class="fa-solid fa-unlock pr-2"></i>Retrieve
                    Code</button>
                <div class="ml-2">
                    <button pButton class="p-button-help" (click)="help()"><i class="fa-solid fa-question pr-2"></i>Get
                        help</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="keysafeDetails && !errorMessage">
    <div class="mt-2 ml-1">
        <img src="/assets/ThriftyLogo.png" style="width: 30vw;">
    </div>
    <div class="ml-2 font-bold text-lg">
        {{branchName}}
    </div>
    <div class="ml-2">
        <span>Your vehicle keys are in the cupboard / safe number below. Please use the codes provided to access.</span>
        <div class="mt-4">
            <table frame=void rules=rows>
                <tr>
                    <td>Cupboard</td>
                    <td class="pl-4 text-900 font-normal">{{keysafeDetails.CupboardDescription}}</td>
                </tr>
                <ng-container *ngIf="keysafeDetails.CupboardCode">
                    <tr>
                        <td>Cupboard Code</td>
                        <td class="pl-4 text-900 font-normal">{{keysafeDetails.CupboardCode}}</td>
                    </tr>
                </ng-container>
                <tr>
                    <td>Keysafe</td>
                    <td class="pl-4 text-900 font-normal">{{keysafeDetails.KeysafeDescription}}</td>
                </tr>
                <tr>
                    <td>Keysafe Code</td>
                    <td class="pl-4 text-900 font-normal">{{keysafeDetails.KeysafeCode}}</td>
                </tr>
            </table>
        </div>
        <div class="mt-4">
            <span>Please note that all cupboards and safes have different codes and the relevant Clarity number must be
                provided separately to access each one.
                If you are unable to access your keys or if you are having any issues please contact
                Babcock PSC on <b>0800 085 4862</b></span>
        </div>
        <div class="mt-4">
            <button pButton (click)="back()"><i class="fa-solid fa-arrow-left pr-2"></i>Back</button>
        </div>
    </div>
</div>