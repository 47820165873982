import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { RetrieveCodeDto } from "../interfaces/RetrieveCodeDto";

@Injectable({
    providedIn: 'root'
})
export class KeysafeService {
    endpoint: string = '/api/Keysafe';

    constructor(private httpService: HttpClient) { }

    getBranchName(branchHash: string) {
        return this.httpService.get<any>(`${this.endpoint}/GetBranchName/${branchHash}`);
    }

    retrieveCode(dto: RetrieveCodeDto) {
        return this.httpService.post<any>(`${this.endpoint}/RetrieveCode`, dto);
    }

    validateToken(token: string) {
        return this.httpService.post(`${this.endpoint}/ValidateToken`, { token: token });
    }

}