import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ServiceDetails } from "../interfaces/ServiceDetails";

@Injectable({
    providedIn: 'root'
})
export class HomeService {
    endpoint: string = '/api/Home';

    constructor(private httpService: HttpClient) { }

    get() {
        return this.httpService.get<ServiceDetails>(this.endpoint)
    }


}