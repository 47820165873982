import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MenubarModule } from 'primeng/menubar';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LogViewerComponent } from './log-viewer/log-viewer/log-viewer.component';
import { TableModule } from 'primeng/table';
import { ThriftyControlsModule } from '@scotgroup/devtest-thrifty-controls';
import { InspectLogEntryComponent } from './log-viewer/inspect-log-entry/inspect-log-entry.component';
import { CardModule } from 'primeng/card';
import { HomeComponent } from './home/home.component';
import { ChipModule } from 'primeng/chip';
import { ButtonModule } from 'primeng/button';
import { HttpLoadingInterceptor, ThriftySharedModule } from '@scotgroup/devtest-thrifty-shared';
import { APP_BASE_HREF, PlatformLocation } from '@angular/common';
import { ConfigService } from './services/ConfigService';
import { LoginComponent } from './login/login.component';
import { LayoutComponent } from './layout/layout.component';
import { InputTextModule } from 'primeng/inputtext';
import { AuthService } from './services/AuthService';
import { concat } from 'rxjs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LogoutComponent } from './logout/logout.component';
import { AuthInterceptor } from './Http/AuthInterceptor';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { UsersComponent } from './users/users.component';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { CreateUserComponent } from './users/create-user/create-user.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApiKeysComponent } from './users/api-keys/api-keys.component';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { KeysafeComponent } from './keysafe/keysafe.component';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';

export function configInit(configService: ConfigService, authService: AuthService) {
  return () => concat(configService.init(), authService.init());
}

@NgModule({
  declarations: [
    AppComponent,
    LogViewerComponent,
    InspectLogEntryComponent,
    HomeComponent,
    LoginComponent,
    LayoutComponent,
    UsersComponent,
    CreateUserComponent,
    LogoutComponent,
    UnauthorizedComponent,
    ApiKeysComponent,
    KeysafeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MenubarModule,
    TableModule,
    ThriftyControlsModule,
    CardModule,
    ChipModule,
    ButtonModule,
    InputTextModule,
    ReactiveFormsModule,
    DynamicDialogModule,
    ThriftySharedModule,
    BrowserAnimationsModule,
    DialogModule,
    ConfirmDialogModule,
    FormsModule,
    RecaptchaModule,
    RecaptchaFormsModule
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: configInit, multi: true, deps: [ConfigService, AuthService, HttpClient] },
    { provide: HTTP_INTERCEPTORS, useClass: HttpLoadingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: APP_BASE_HREF,
      useFactory: (s: PlatformLocation) => s.getBaseHrefFromDOM(),
      deps: [PlatformLocation]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
