import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ThValidators } from '@scotgroup/devtest-thrifty-shared';
import { firstValueFrom } from 'rxjs';
import { KeysafeDetails } from '../interfaces/KeysafeDetails';
import { RetrieveCodeDto } from '../interfaces/RetrieveCodeDto';
import { KeysafeService } from '../services/KeysafeService';

@Component({
  selector: 'app-keysafe',
  templateUrl: './keysafe.component.html',
  styleUrls: ['./keysafe.component.scss']
})
export class KeysafeComponent implements OnInit {
  branchGUID!: string;
  branchName?: string;
  form!: FormGroup;
  token?: string;
  keysafeDetails?: KeysafeDetails;
  resolved: boolean = false;
  errorMessage?: string;
  helpMessage: boolean = false;
  branchInvalid: boolean = false;

  get ShowMainScreen() { return !this.keysafeDetails && !this.errorMessage && !this.helpMessage && this.form && !this.branchInvalid }

  constructor(private keysafeService: KeysafeService, private route: ActivatedRoute, private router: Router) { }

  async ngOnInit() {
    this.branchGUID = this.route.snapshot.params['id'];
    const branch = await firstValueFrom(this.keysafeService.getBranchName(this.branchGUID));

    if (!branch.valid) {
      this.branchInvalid = true;
      return;
    }

    this.branchName = branch.value;

    this.form = new FormGroup({
      clarityNumber: new FormControl(null, ThValidators.requiredNotEmpty)
    });
  }

  async retrieveCode() {
    this.keysafeDetails = undefined;

    this.form.markAllAsTouched();
    if (this.form.invalid) return;

    const formData = this.form.getRawValue();
    const dto: RetrieveCodeDto = {
      BranchGUID: this.branchGUID,
      ClarityNumber: formData.clarityNumber,
      ValidationToken: this.token ?? ''
    };

    const response = await firstValueFrom(this.keysafeService.retrieveCode(dto));

    if (response.valid) {
      this.errorMessage = undefined;
      this.keysafeDetails = response.value;
      return;
    }

    this.errorMessage = response.errors['Error'];
  }

  recaptchaResolved(token: any) {
    if (!token) { //expired
      this.resolved = false;
      this.token = undefined;
      return;
    }

    this.resolved = true;
    this.token = token;
  }

  help() {
    this.helpMessage = true;
  }

  back() {
    window.location.reload();
  }

}
