<div class="layout">
    <p-menubar [model]="items">
        <ng-template pTemplate="start">
            <p>{{configService.currentConfig.name}}</p>
        </ng-template>
        <ng-template pTemplate="end">
            <ng-container *ngIf="authService.currentAuthStatus.isLoggedIn">
                Hello {{authService.currentAuthStatus.name}}! <a [routerLink]="['/logout']">LogOut</a>
            </ng-container>
            <ng-container *ngIf="!authService.currentAuthStatus.isLoggedIn">
                <a [routerLink]="['/login']">LogIn</a>
            </ng-container>
        </ng-template>
    </p-menubar>
    <div class="ml-2">
        <router-outlet></router-outlet>
    </div>
</div>