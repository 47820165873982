import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Log } from '../../interfaces/Log';
import { LogService } from '../../services/LogService';

@Component({
  selector: 'app-inspect-log-entry',
  templateUrl: './inspect-log-entry.component.html',
  styleUrls: ['./inspect-log-entry.component.scss']
})
export class InspectLogEntryComponent implements OnInit {
  logDetails!: Log;

  constructor(private logService: LogService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    const id = this.route.snapshot.params['id'];

    this.logService.getLogDetails(id).subscribe(details => {
      this.logDetails = details;
      this.formatStackTrace();

      if (!details.logEvent) return;

      let properties = JSON.parse(details.logEvent);
      delete properties['Exception'];
      delete properties['MessageTemplate'];
      delete properties['Renderings'];

      this.logDetails.logEvent = properties;
    });
  }

  formatStackTrace() {
    let formatted = this.logDetails.exception;
    if (!formatted) return;

    let split = formatted.split('\r\n');

    for (var i = 0; i < split.length; i++) {
      let string = split[i];

      let dotIndex = string.lastIndexOf('.');
      let openBracketIndex = string.lastIndexOf('(');

      if (dotIndex == -1 || openBracketIndex == -1)
        continue;

      split[i] = string.slice(0, dotIndex) + '<mark><b>' +
        string.slice(dotIndex, openBracketIndex) + '</b></mark>' +
        string.slice(openBracketIndex, string.length);
    }

    split[0] = '<b>' + split[0] + '</b>';

    formatted = split.join('\r\n');

    this.logDetails.exception = formatted;
  }
}



