import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserDto } from '../interfaces/UserDto';
import { CreateUserDto } from '../interfaces/CreateUserDto';
import { FormResponse } from '../interfaces/form-helpers/FormResponse';
import { ApiCredentials } from '../interfaces/ApiCredentials';
import { UserKeyInfo } from '../interfaces/UserKeyInfo';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  endpoint: string = '/api/User/';

  constructor(private httpService: HttpClient) { }

  createUser(dto: CreateUserDto) {
    return this.httpService.post<FormResponse>(this.endpoint + 'CreateUser', dto);
  }

  getUsers() {
    return this.httpService.get<UserDto[]>(this.endpoint + 'GetUsers');
  }

  getUser(userId: string) {
    return this.httpService.get<UserDto>(this.endpoint + 'GetUser/' + userId);
  }
  
  deactivateUser(userId: string) {
    return this.httpService.put(this.endpoint + 'DeactivateUser/' + userId, {});
  }

  generateApiKey(userId: string) {
    return this.httpService.post<ApiCredentials>(this.endpoint + 'GenerateApiKey/' + userId, {});
  }

  getUserKeyInfo(userId: string) {
    return this.httpService.get<UserKeyInfo>(this.endpoint + 'GetUserKeyInfo/' + userId);
  }

  regenerateApiKey(userId: string) {
    return this.httpService.post<ApiCredentials>(this.endpoint + 'ReGenerateApiKey/' + userId, {});
  }

  deleteApiKey(userId: string) {
    return this.httpService.delete(this.endpoint + 'DeleteApiKey/' + userId);
  }

}
