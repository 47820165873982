import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AuthService } from "../services/AuthService";

@Injectable({
    providedIn: 'root',
})
export class LoginAuthGuard implements CanActivate {
    constructor(@Inject(DOCUMENT) private document: Document, private authService: AuthService, private router: Router) { }

    canActivate(): boolean {
        let loggedIn = this.authService.currentAuthStatus.isLoggedIn;

        if (loggedIn) {
            this.document.defaultView?.location.assign('/');
        }

        return !loggedIn;
    }
}
