import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom, Subject, tap } from "rxjs";
import { AuthState } from "../interfaces/AuthState";
import { LoginResponse } from "../interfaces/LoginResponce";

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private endpoint: string = '/api/Account';
    private _authChanged = new Subject<boolean>();
    private loggedOutUser: AuthState = { isLoggedIn: false, name: "", email: "", authType: "" };

    currentAuthStatus!: AuthState;
    authChangedEvent = this._authChanged.asObservable();

    constructor(private httpService: HttpClient) {
        this.currentAuthStatus = this.loggedOutUser;
    }

    get() {
        return this.httpService.get<AuthState>(this.endpoint);
    }

    init() {
        return this.get().pipe(tap(value => {
            this.checkAuthDetails(value);
        }));
    }

    async signInUser(userName: string, password: string) {
        var response = await firstValueFrom(this.httpService.post<LoginResponse>(this.endpoint + "/Login", { userName: userName, password: password }))

        if (response.success) {
            this.checkAuthDetails(await firstValueFrom(this.get()));
        }

        return response;
    }

    private checkAuthDetails(authState: AuthState) {
        //Check if the user is logged in
        if (!authState.isLoggedIn) {
            this.logOutUser();
            return;
        }
        
        this.currentAuthStatus = authState
        this._authChanged.next(true);
    }

    async logOutUser() {
        if (this.currentAuthStatus == this.loggedOutUser) {
            return;
        }

        this.currentAuthStatus = this.loggedOutUser;
        this._authChanged.next(false);
        await firstValueFrom(this.httpService.post<LoginResponse>(this.endpoint + "/Logout", {}))
    }

    //Returns boolean if user has role (Case Senstive)
    userInRole(roleName: string) {
        let roles = [roleName];
        return this.userInRoles(roles);
    }

    //Returns boolean if user has role (Case Senstive)
    async userInRoles(roleNames: string[]) {
        // return await firstValueFrom(this.http.post<boolean>('/shared/Api/AngularConfig/userInRole', roleNames));
        console.error("Not implemented")
    }

}