<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-dialog header="API key created!" [(visible)]="showGeneratedKeys" [draggable]="false" [resizable]="false">
    <ng-container *ngIf="apiCredentials">
        <div>
            <span>Do not close this modal until you have copied the secret key - it will not be displayed again.</span>
        </div>
        <div class="mt-4 flex flex-column">
            <span><b>Consumer Key</b> </span>
            <span>{{apiCredentials.consumerKey}}</span>
        </div>
        <div class="mt-2 flex flex-column">
            <span><b>Secret</b></span>
            <span class="text-red-500">{{apiCredentials.secret}}</span>
        </div>
    </ng-container>
</p-dialog>

<div [class]="showGeneratedKeys ? 'blurredBackground' : 'normalBackground'">
    <ng-container *ngIf="user">
        <div>
            <b>User:</b> {{user.username}}
            <ng-container *ngIf="user.displayName">({{user.displayName}})</ng-container>
            <span>, {{user.email}}</span>
        </div>
        <hr>
        <ng-container *ngIf="keys && keys.length != 0">
            <div class="mt-3">
                <span><b>Existing Key</b></span>
                <div class="mt-2">
                    <p-table [value]="keys" styleClass="p-datatable-sm">
                        <ng-template pTemplate="header">
                            <tr class="text-sm">
                                <th style="width: 25%;">Name</th>
                                <th style="width: 50%;">Consumer Key</th>
                                <th style="width: 25%;">Date Generated</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-key>
                            <tr class="text-sm">
                                <td>{{key.name}}</td>
                                <td>{{key.consumerKey}}</td>
                                <td>{{key.dateGenerated | date:'dd/MM/yyyy HH:mm:ss'}}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </ng-container>
        <div>
            <ng-container *ngIf="keys && keys.length != 0">
                <div class="flex flex-row mt-3">
                    <div>
                        <button pButton type="button" (click)="reGenerateApiKey()" label="Re-Generate API key"
                        class="p-button-warning"></button>
                    </div>
                    <div class="ml-1">
                        <button pButton type="button" (click)="deleteApiKey()" label="Delete API key"
                            class="p-button-danger"></button>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!keys || keys.length == 0">
                <div class="mt-2">
                    <button pButton type="button" (click)="generateApiKey()" label="Generate API key" [disabled]="generated"
                    class="p-button-success"></button>
                </div>                
            </ng-container>
        </div>
    </ng-container>
</div>