<div class="grid border-round-md" [formGroup]="createUserForm">
    <div class="p-2" [formGroup]="createUserForm">
        <th-text-input [formGroup]="createUserForm" formControlName="username" label="Username" [showAsRequired]="true">
        </th-text-input>
        <th-text-input [formGroup]="createUserForm" formControlName="displayName" label="Display Name"></th-text-input>
        <th-text-input [formGroup]="createUserForm" formControlName="email" label="Email Address"
            [showAsRequired]="true"></th-text-input>
        <th-text-input [formGroup]="createUserForm" formControlName="password" label="Password" [showAsRequired]="true">
        </th-text-input>
        <ng-container *ngIf="errorMessages.length > 0">
            <div class="mt-4 text-red-500"><ul>
                <div *ngFor="let msg of errorMessages">
                    <li>{{msg}}</li>
                </div>
            </ul>                
            </div>
        </ng-container>
        <div class="p-dialog-footer footer">
            <button pButton type="button" (click)="cancel()" label="Cancel" class="p-button-secondary"></button>
            <button pButton type="button" (click)="submitCreateUser()" label="Submit" class="p-button-primary"></button>
        </div>
    </div>
</div>