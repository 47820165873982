import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/AuthGuard';
import { LoginAuthGuard } from './guards/LoginAuthGuard';
import { HomeComponent } from './home/home.component';
import { KeysafeComponent } from './keysafe/keysafe.component';
import { LayoutComponent } from './layout/layout.component';
import { InspectLogEntryComponent } from './log-viewer/inspect-log-entry/inspect-log-entry.component';
import { LogViewerComponent } from './log-viewer/log-viewer/log-viewer.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { ApiKeysComponent } from './users/api-keys/api-keys.component';
import { UsersComponent } from './users/users.component';

const routes: Routes = [
  {
    path: "", component: LayoutComponent, children: [
      { path: "", component: HomeComponent, canActivate: [AuthGuard] },
      { path: "logs", component: LogViewerComponent, canActivate: [AuthGuard] },
      { path: "logs/:id", component: InspectLogEntryComponent, canActivate: [AuthGuard] },
      { path: "unauthorized", component: UnauthorizedComponent },
      { path: "users", component: UsersComponent, canActivate: [AuthGuard] },
      { path: "users/:id", component: ApiKeysComponent, canActivate: [AuthGuard] }      
    ]
  },
  { path: "keysafe/:id", component: KeysafeComponent },
  { path: "login", component: LoginComponent, canActivate: [LoginAuthGuard] },
  { path: "logout", component: LogoutComponent }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
