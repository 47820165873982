import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ThValidators } from '@scotgroup/devtest-thrifty-shared';
import { AuthService } from '../services/AuthService';
import { ConfigService } from '../services/ConfigService';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  signInForm!: FormGroup;
  signInError: string = "";

  constructor(@Inject(DOCUMENT) private document: Document,public configService: ConfigService, private authService: AuthService, private formBuilder: FormBuilder, private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.signInForm = this.formBuilder.group({
      userName: ["", ThValidators.requiredNotEmpty],
      password: ["", ThValidators.requiredNotEmpty]
    })
  }

  async submitSignIn() {
    if (!this.signInForm.valid) {
      this.signInForm.controls["userName"].markAsDirty;
      this.signInForm.controls["password"].markAsDirty;
      return;
    }

    var result = await this.authService.signInUser(this.signInForm.value.userName, this.signInForm.value.password);

    if (result.success) {
      if (this.activatedRoute.snapshot.queryParams["returnUrl"]) {
        this.document.defaultView?.location.assign(this.activatedRoute.snapshot.queryParams["returnUrl"]);
        return;
      }
      this.router.navigate(["/"]);
      return;
    }

    this.signInError = result.errorMessage;
  }



}
