<div class="grid">
    <div class="col-6">
        <p-card header="Service Information">
            <ul class="list-none p-0 m-0">
                <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                    <div class="text-500 w-6 md:w-2 font-medium">Name</div>
                    <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" *ngIf="serviceDetails">{{serviceDetails.basic.serviceName}}</div>
                </li>
            </ul>
        </p-card>
    </div>
    <div class="col-6">
        <p-card header="Hangfire Status">
            <ul class="list-none p-0 m-0">
                <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                    <div class="text-500 w-6 md:w-2 font-medium">Servers</div>
                    <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                        <ng-container *ngFor="let server of serviceDetails.hangfire.servers">
                            <p-chip [label]="server" class="mr-2"></p-chip>
                        </ng-container>
                    </div>
                </li>
                <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                    <div class="text-500 w-6 md:w-2 font-medium">Processing Jobs</div>
                    <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" *ngIf="serviceDetails">{{serviceDetails.hangfire.processingJobs}}</div>
                </li>
                <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                    <div class="text-500 w-6 md:w-2 font-medium">Failed Jobs</div>
                    <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" *ngIf="serviceDetails">{{serviceDetails.hangfire.failedJobs}}</div>
                </li>
                <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                    <div class="text-500 w-6 md:w-2 font-medium">Recurring Jobs</div>
                    <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" *ngIf="serviceDetails">{{serviceDetails.hangfire.recurringJobs}}</div>
                </li>
                <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                    <div class="text-500 w-6 md:w-2 font-medium">Scheduled Jobs</div>
                    <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" *ngIf="serviceDetails">{{serviceDetails.hangfire.scheduledJobs}}</div>
                </li>
                <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                    <div class="text-500 w-6 md:w-2 font-medium"></div>
                    <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1" *ngIf="serviceDetails"></div>
                    <div class="w-6 md:w-2 flex justify-content-end">
                        <a pButton pRipple label="Manage" icon="pi pi-search" class="p-button-text" href="/hangfire"></a>
                    </div>
                </li>
            </ul>
        </p-card>
    </div>
</div>