import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AuthService } from '../services/AuthService';
import { ConfigService } from '../services/ConfigService';

@Component({
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  items!: MenuItem[];

  constructor(public configService: ConfigService, public authService: AuthService) { }

  ngOnInit() {
    this.items = [
      {
        label: 'Home',
        icon: 'pi pi-home',
        routerLink: ["/"]
      },
      {
        label: 'Tools',
        items: [
          { label: 'Logs', icon: 'pi pi-book', routerLink: ["/logs"] },
          { label: 'Hangfire Dashboard', icon: 'pi pi-directions-alt', url: '/hangfire' },
          { label: 'User Management', icon: 'pi pi-user-edit', routerLink: ["/users"] },
          { label: 'Swagger', icon: 'pi pi-book', url: '/swagger' }
        ]
      }
    ];
  }
}
