import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableConfiguration, DataTableCustomAction, DataTableRowConfiguration, SortConfiguration, TableDataType, TableFilterType, TableSelection } from '@scotgroup/devtest-thrifty-controls';
import { DialogService } from 'primeng/dynamicdialog';
import { UserDto } from '../interfaces/UserDto';
import { UserService } from '../services/UserService';
import { CreateUserComponent } from './create-user/create-user.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  providers: [DialogService]
})
export class UsersComponent implements OnInit {
  users!: UserDto[];
  tableDefinition: DataTableConfiguration = new DataTableConfiguration({
    dataKey: "id",
    columns: [
      new DataTableRowConfiguration({ field: 'id', header: 'Id', datatype: TableDataType.text, display: false }),
      new DataTableRowConfiguration({ field: 'username', header: 'Username', datatype: TableDataType.text, filterType: TableFilterType.text }),
      new DataTableRowConfiguration({ field: 'email', header: 'Email', datatype: TableDataType.text, filterType: TableFilterType.text }),
      new DataTableRowConfiguration({ field: 'displayName', header: 'Display Name', datatype: TableDataType.text, filterType: TableFilterType.text }),
      new DataTableRowConfiguration({ datatype: TableDataType.customAction }),

    ],
    customActions: [new DataTableCustomAction({
      toolTip: "Generate API key",
      icon: "fa-solid fa-key",
      enabled: () => true,
      action: (event, key) => this.generateApiKey(key)
    }),
    new DataTableCustomAction({
      toolTip: "Deactivate User",
      icon: "fa-solid fa-person-circle-minus",
      enabled: () => true,
      action: (event, key) => this.deactivateUser(key)
    })],
    scrollHeight: '70vh',
    rowHover: true,
  });

  constructor(private userService: UserService, private dialogService: DialogService, private router: Router) { }

  ngOnInit(): void {
    this.getUsers();
  }

  getUsers() {
    this.userService.getUsers().subscribe(users => {
      this.users = users;
    });
  }

  openCreateUserModal() {
    const ref = this.dialogService.open(CreateUserComponent, {
      header: "Create User"
    });

    ref.onClose.subscribe(success => {
      if (success) this.getUsers();
    })
  }

  generateApiKey(userId: string) {
    this.router.navigateByUrl('/users/' + userId);
  }

  deactivateUser(userId: string) {
    this.userService.deactivateUser(userId).subscribe(() => {
      this.getUsers();
    });
  }

}
