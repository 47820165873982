import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ThValidators } from '@scotgroup/devtest-thrifty-shared';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormResponse } from 'src/app/interfaces/form-helpers/FormResponse';
import { UserService } from 'src/app/services/UserService';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {
  createUserForm!: FormGroup;
  errorMessages: string[] = [];

  constructor(private formBuilder: FormBuilder, private ref: DynamicDialogRef, private userService: UserService) { }

  ngOnInit(): void {
    this.createUserForm = this.formBuilder.group({
      username: ["", ThValidators.requiredNotEmpty],
      displayName: "",
      email: ["", ThValidators.requiredNotEmpty],
      password: ["", ThValidators.requiredNotEmpty]
    });
  }

  cancel() {
    this.ref.close();
  }

  submitCreateUser() {
    let formData = this.createUserForm.getRawValue();
    let dto = {
      username: formData.username,
      displayName: formData.displayName,
      email: formData.email,
      password: formData.password,
    };

    this.userService.createUser(dto).subscribe((response: FormResponse) => {
      this.errorMessages = [];

      if(!response.valid) {
        this.errorMessages = response.fieldErrorMessages.map(x => x.errorMessage);
        return;
      }

      this.ref.close(true);
    });
  }

}
