import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ServiceDetails } from '../interfaces/ServiceDetails';
import { HomeService } from '../services/HomeService';

@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  serviceDetails!: ServiceDetails;
  serviceInformationSub!: Subscription;
  constructor(private homeService: HomeService) { }

  ngOnInit(): void {
    this.serviceInformationSub = this.homeService.get().subscribe(x => this.serviceDetails = x);
  }

  ngOnDestroy(): void {
    this.serviceInformationSub?.unsubscribe();
  }

}
