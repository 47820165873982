import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { tap } from "rxjs";
import { Config } from "../interfaces/Config";

@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    endpoint: string = '/api/Config';
    currentConfig!: Config;

    constructor(private httpService: HttpClient) { }

    get() {
        return this.httpService.get<Config>(this.endpoint);
    }

    init() {
        return this.get().pipe(tap(value => {
            this.currentConfig = value;
        }));
    }


}