<div class="align-items-center flex justify-content-center lg:px-8 md:px-6 px-4 py-8 surface-ground">
    <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6">
        <div class="text-center mb-5">
            <img src="assets/images/blocks/logos/hyper.svg" alt="Image" height="50" class="mb-3">
            <div class="text-900 text-3xl font-medium mb-3">Welcome Back</div>
            <span class="text-600 font-medium line-height-3">{{configService.currentConfig.name}}</span>
            <!-- <a class="font-medium no-underline ml-2 text-blue-500 cursor-pointer">Create today!</a> -->
        </div>

        <form [formGroup]="signInForm" novalidate (ngSubmit)="submitSignIn()">
            <div>
                <label for="email1" class="block text-900 font-medium mb-2">Username</label>
                <input formControlName="userName" id="email1" type="text" placeholder="Username" pInputText class="w-full mb-3">

                <label for="password1" class="block text-900 font-medium mb-2">Password</label>
                <input formControlName="password" id="password1" type="password" placeholder="Password" pInputText class="w-full mb-3">

                <div class="flex align-items-center justify-content-between mb-6">
                    <a class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer">Forgot password?</a>
                    <a class="font-medium no-underline ml-2 text-red-500 text-right cursor-pointer" *ngIf="signInError">{{signInError}}</a>
                </div>

                <button type="submit" pButton pRipple label="Sign In" icon="pi pi-user" class="w-full"></button>
            </div>
        </form>
    </div>
</div>