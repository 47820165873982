import { Component } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Config } from './interfaces/Config';
import { ConfigService } from './services/ConfigService';
import { HomeService } from './services/HomeService';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  

}
