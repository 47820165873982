import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataTableConfiguration, DataTableRowConfiguration, TableDataType, TableSelection } from '@scotgroup/devtest-thrifty-controls';
import { ConfirmationService } from 'primeng/api';
import { firstValueFrom } from 'rxjs';
import { ApiCredentials } from 'src/app/interfaces/ApiCredentials';
import { UserDto } from 'src/app/interfaces/UserDto';
import { UserKeyInfo } from 'src/app/interfaces/UserKeyInfo';
import { UserService } from 'src/app/services/UserService';

@Component({
  selector: 'app-api-keys',
  templateUrl: './api-keys.component.html',
  styleUrls: ['./api-keys.component.scss'],
  providers: [ConfirmationService]
})
export class ApiKeysComponent implements OnInit {
  user!: UserDto;
  apiCredentials!: ApiCredentials;
  generated: boolean = false;
  showGeneratedKeys: boolean = false;
  keys!: UserKeyInfo[];

  constructor(private userService: UserService, private route: ActivatedRoute, private confirmationService: ConfirmationService) { }

  ngOnInit(): void {
    let userId = this.route.snapshot.params['id'];

    this.userService.getUser(userId).subscribe(user => {
      this.user = user;
    });

    this.userService.getUserKeyInfo(userId).subscribe(keyInfo => {
      if (keyInfo === null) return;
      this.keys = new Array(keyInfo);
    });
  }

  generateApiKey() {
    this.generateKeyAndRefresh(false);
  }

  private async generateKeyAndRefresh(regenerate: boolean) {
    var keys: ApiCredentials;

    if(regenerate) 
      keys = await firstValueFrom(this.userService.regenerateApiKey(this.user.id!));    
    else 
      keys = await firstValueFrom(this.userService.generateApiKey(this.user.id!));  

    this.apiCredentials = keys;
    this.generated = true;
    this.showGeneratedKeys = true;

    let keyInfo = await firstValueFrom(this.userService.getUserKeyInfo(this.user.id!));

    if (keyInfo === null) return;
    this.keys = new Array(keyInfo);
  }

  reGenerateApiKey() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to re-generate this API key?',
      accept: () => {    
        this.generateKeyAndRefresh(true);
      }
    });
  }

  deleteApiKey() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this API key?',
      accept: () => {
        this.userService.deleteApiKey(this.user.id!).subscribe(x => {
          this.generated = false;
          this.showGeneratedKeys = false;
          this.keys = [];
        });
      }
    });
  }
}
