import { Inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { RedirectService } from '@scotgroup/devtest-thrifty-shared';
import { ThInputErrorsComponent } from '@scotgroup/devtest-thrifty-controls';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(@Inject(DOCUMENT) private document: Document, private router: Router, private redirectService: RedirectService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: any) => {
        if (error.status == 401 || error.status == 0) {
          let path = encodeURIComponent(location.pathname);
          console.log(path);
          // this.router.navigate(['/login'],{queryParams:{returnUrl: path}})
          this.document.defaultView?.location.assign("/login?returnUrl=" + path);
        } else if (error.status === 403) {
          this.router.navigate(['/unauthorized']);
        }
        return of(error);
      })
    )
  }
}