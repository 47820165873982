import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AuthService } from "../services/AuthService";

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(@Inject(DOCUMENT) private document: Document, private authService: AuthService, private router: Router) { }

    canActivate(): boolean {
        // alert(this.router.url);
        let loggedIn = this.authService.currentAuthStatus.isLoggedIn;

        if (!loggedIn) {
            let path = encodeURIComponent(location.pathname);
            this.document.defaultView?.location.assign("/login?returnUrl=" + path);
        }

        return loggedIn;
    }
}
