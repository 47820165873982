import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableConfiguration, DataTableCustomAction, DataTableRowConfiguration, SortConfiguration, TableDataType, TableFilterType, TableSelection } from '@scotgroup/devtest-thrifty-controls';
import { Log } from 'src/app/interfaces/Log';
import { LogService } from 'src/app/services/LogService';

@Component({
  selector: 'app-log-viewer',
  templateUrl: './log-viewer.component.html',
  styleUrls: ['./log-viewer.component.scss']
})
export class LogViewerComponent implements OnInit {
  logEvents: Log[] = [];
  tableDefinition: DataTableConfiguration = new DataTableConfiguration({
    dataKey: "id",
    columns: [
      new DataTableRowConfiguration({ field: 'timeStamp', header: 'Date', datatype: TableDataType.timestamp, filterType: TableFilterType.date }),
      new DataTableRowConfiguration({ field: 'message', header: 'Message', datatype: TableDataType.text, filterType: TableFilterType.text }),
      new DataTableRowConfiguration({ field: 'level', header: 'Level', datatype: TableDataType.text, filterType: TableFilterType.text })
    ],
    customActions: [
      new DataTableCustomAction({
        toolTip: "View Details",
        icon: "fa-solid fa-circle-info",
        enabled: () => true,
        action: (event, key) => this.showDetails(key)
      })
    ],
    defaultSortColumns: [
      new SortConfiguration({ field: "timeStamp", direction: -1 })
    ],
    scrollHeight: '78vh',
    rowHover: true
  });

  constructor(private logService: LogService, private router: Router) { }

  ngOnInit(): void {
    this.logService.getLogs().subscribe(logs => {
      this.logEvents = logs;
    });
  }

  showDetails(key: number) {
    this.router.navigateByUrl('/logs/' + key);
  }

}
