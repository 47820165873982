import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Log } from '../interfaces/Log';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  endpoint: string = '/api/Log/';

  constructor(private httpService: HttpClient) { }

  getLogs() {
    return this.httpService.get<Log[]>(this.endpoint + 'GetLogs');
  }   

  getLogDetails(id: number) {
    return this.httpService.get<Log>(this.endpoint + 'GetLogDetails/' + id);
  }   
}
