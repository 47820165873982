<div class="flex flex-row">
    <span class="text-3xl text-900 font-light">{{logDetails.message}}</span>
</div>
<div class="flex flex-row mt-1 text-sm">
    <div><span><b>Date: </b>{{logDetails.timeStamp | date: 'dd/MM/yyyy'}}</span></div>
    <div class="ml-2"><span><b>Time: </b>{{logDetails.timeStamp | date: 'HH:mm:ss.SS'}}</span></div>
</div>
<hr>
<ng-container *ngIf="logDetails.exception">
    <div class="mt-1">
        <div id="codeBox" class="mt-1 p-2"><code id="exceptionSpan" [innerHTML]="logDetails.exception"></code></div>
    </div>
</ng-container>
<div class="mt-1">
    <div id="propertiesBox" class="mt-1 p-2">
        <pre class="text-base">{{logDetails.logEvent | json}}</pre>
    </div>
</div>